<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="col-12">
        <router-link :to="'/programme'">
          Programme>
        </router-link>
        <span>Création de programme</span>
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Création de programme
          </div>
        </div>
      </div>
    </div>
    <div class="inner_container">
      <div class="row "
           id="notification">
        <Notif :notif="notif" />
      </div>
      <form>
        <div class="row g-3 align-items-center mx-4">
          <div class="col-md-3">
            <label>Catégorie du programme</label>
            <select class="form-control" 
                    name="" 
                    id=""
                    v-model="form.categorie_programme">
              <option value="programme_investissement">Programme d'investissement</option>
              <option value="programme_entretien_routier">programme d'entretien routier</option>
            </select>
            <span v-if="form.errors().has('categorie_programme') && affiche.categorie_programme !== 'categorie_cloture_programme'"
                  v-text="form.errors().get('categorie_cloture_programme')"
                  class="errorMsg"> </span>
          </div>
          <div class="col-md-3">
            <label class="req">Nom du programme</label>
            <input type="text"
                   name="libelle"
                   v-model="form.nom_programme"
                   class="form-control form-control-lg"
                   v-on:click="removeSPan('nom_programme')"
                   aria-label=".form-control-lg example" />
            <span v-if="form.errors().has('nom_programme') && affiche.nom_programme !== 'nom_programme'"
                  v-text="form.errors().get('nom_programme')"
                  class="errorMsg"> </span>
          </div>
          <div class="col-md-3">
            <label>Année d'exercice</label>
            <input
              type="number"
              name="annee_exercice"
              v-model="form.annee_exercice"
              :ref="'annee_exercice'"
              class="form-control form-control-lg"
              v-on:click="removeSPan('annee_exercice')"
              v-on:change="getDate()"
              aria-label=".form-control-lg example"
            />
            <span v-if="form.errors().has('categorie_programme') && affiche.categorie_programme !== 'categorie_cloture_programme'"
                  v-text="form.errors().get('categorie_cloture_programme')"
                  class="errorMsg"> </span>
          </div>
          <div class="col-md-3">
            <label class="req">Montant initial</label>
            <input
              type="text"
              name="budget"
              min="0"
              max="999999999999"
              v-model="montant_initial_programme"
              class="form-control form-control-lg"
              @change="checkbudget(montant_initial_programme)"
              @input="addSpace('montant_initial_programme')"
              v-on:click="removeSPan('montant_initial_programme')"
              aria-label=".form-control-lg example"
            />
            <span v-if="form.errors().has('montant_initial_programme') && affiche.montant_initial_programme !== 'montant_initial_programme'"
                  v-text="form.errors().get('montant_initial_programme')"
                  class="errorMsg"> </span>
          </div>
        </div>
        <div class="row g-3 align-items-center mx-4">
          <div class="col-md-3">
            <label class="req">Date début</label>
            <input
              type="date"
              name="date_debut"
              v-model="form.date_debut"
              :ref="'date_debut'"
              class="form-control form-control-lg"
              v-on:click="removeSPan('date_debut')"
              v-on:change="getDate()"
              aria-label=".form-control-lg example"
            />
            <span v-if="form.errors().has('date_debut') && affiche.date_debut !== 'date_debut'"
                  v-text="form.errors().get('date_debut')"
                  class="errorMsg"> </span>
          </div>
          <div class="col-md-3">
            <label class="req">Date fin</label>
            <input
              type="date"
              name="date_fin"
              v-model="form.date_fin"
              :ref="'date_fin'"
              class="form-control form-control-lg"
              v-on:click="removeSPan('date_fin')"
              v-on:change="getDate()"
              aria-label=".form-control-lg example"
            />
            <span v-if="form.errors().has('date_fin') && affiche.date_fin !== 'date_fin'"
                  v-text="form.errors().get('date_fin')"
                  class="errorMsg"> </span>
          </div>
          <div class="col-md-3">
            <label>Date de validation</label>
            <input type="date"
                   name="libelle"
                   v-model="form.date_validation_programme"
                   class="form-control form-control-lg"
                   v-on:click="removeSPan('date_validation_programme')"
                   aria-label=".form-control-lg example" />
            <span v-if="form.errors().has('date_validation_programme') && affiche.date_validation_programme !== 'date_validation_programme'"
                  v-text="form.errors().get('date_validation_programme')"
                  class="errorMsg"> </span>
          </div>
          <div class="col-md-3">
            <label>Date de lancement</label>
            <input
              type="date"
              name="dateLancement"
              min="0"
              max="999999999999"
              v-model="form.date_lancement_programme"
              class="form-control form-control-lg"
              @change="checkbudget(form.date_lancement_programme)"
              v-on:click="removeSPan('date_lancement_programme')"
              aria-label=".form-control-lg example"
            />
            <span v-if="form.errors().has('date_lancement_programme') && affiche.date_lancement_programme !== 'date_lancement_programme'"
                  v-text="form.errors().get('date_lancement_programme')"
                  class="errorMsg"> </span>
          </div>
        </div>
        <div class="row g-3 align-items-center mx-4">
          
          <div class="col-md-3">
            <label>Date de clôture</label>
            <input
              type="date"
              name="date_cloture"
              v-model="form.date_cloture_programme"
              :ref="'date_cloture_programme'"
              class="form-control form-control-lg"
              v-on:click="removeSPan('date_cloture_programme')"
              v-on:change="getDate()"
              aria-label=".form-control-lg example"
            />
            <span v-if="form.errors().has('date_cloture_programme') && affiche.date_cloture_programme !== 'date_cloture_programme'"
                  v-text="form.errors().get('date_cloture_programme')"
                  class="errorMsg"> </span>
          </div>
          <div class="col-md-3">
            <label>Date de rapport de clôture</label>
            <input
              type="date"
              name="date_transmission_rapport_cloture_programme"
              v-model="form.date_transmission_rapport_cloture_programme"
              :ref="'date_transmission_rapport_cloture_programme'"
              class="form-control form-control-lg"
              v-on:click="removeSPan('date_transmission_rapport_cloture_programme')"
              v-on:change="getDate()"
              aria-label=".form-control-lg example"
            />
            <span v-if="form.errors().has('date_transmission_rapport_cloture_programme') && affiche.date_transmission_rapport_cloture_programme !== 'date_transmission_rapport_cloture_programme'"
                  v-text="form.errors().get('date_transmission_rapport_cloture_programme')"
                  class="errorMsg"> </span>
          </div>
        </div>
        <div class="row g-3 align-items-center mx-4">
          
          <!-- <div class="col-md-4">
            <label>Montant révisé</label>
            <input
              type="text"
              name="budget"
              min="0"
              max="999999999999"
              v-model="montant_revise"
              class="form-control form-control-lg"
              @change="checkbudget(montant_revise)"
              @input="addSpace('montant_revise')"
              v-on:click="removeSPan('montant_revise')"
              aria-label=".form-control-lg example"
            />
            <span v-if="form.errors().has('montant_revise') && affiche.montant_revise !== 'montant_revise'"
                  v-text="form.errors().get('montant_revise')"
                  class="errorMsg"> </span>
          </div>
          <div class="col-md-4">
            <label>Montant définitif</label>
            <input
              type="text"
              name="budget"
              min="0"
              max="999999999999"
              v-model="montant_definitif"
              class="form-control form-control-lg"
              @change="checkbudget(montant_definitif)"
              @input="addSpace('montant_definitif')"
              v-on:click="removeSPan('montant_definitif')"
              aria-label=".form-control-lg example"
            />
            <span v-if="form.errors().has('montant_definitif') && affiche.montant_definitif !== 'montant_definitif'"
                  v-text="form.errors().get('montant_definitif')"
                  class="errorMsg"> </span>
          </div> -->
        </div>
        <div class="row g-3 align-items-center mx-4">
          <div class="col-md-12">
            <label>Description</label>
            <textarea class="form-control"
                      id="exampleFormControlTextarea1"
                      name="description"
                      v-model="form.description"
                      v-on:click="removeSPan('description')"
                      rows="3"></textarea>
            <span v-if="form.errors().has('description') && affiche.description !== 'description'"
                  v-text="form.errors().get('description')"
                  class="errorMsg"> </span>
          </div>
        </div>
      </form>
      <div class="table_holder">
        <div class="tab_holder">
          <div class="col_table"
               v-if="progrubriques.donnees">
            <div class="col_header">
              <div class="row">
                <div class="col-6">DESIGNATION</div>
                <div class="col-4 text-center">OBSERVATION</div>
                <div class="col-2 text-center">MONTANT</div>
              </div>
            </div>
            <div class="row no-mr"
                 v-for="(rubrique, rubkey) in progrubriques.donnees"
                 :key="rubkey">
              <div class="col-md-12"
                   v-if="activites.length > 0">
                <div class="row light_row">
                  <div class="col-md-12">
                    <label>{{ rubrique.libelle }}</label>
                  </div>
                </div>
                <div class="row alter tableview"
                     v-for="(activite, actkey) in rubrique.activites"
                     :key="actkey">
                  <div class="col-md-6 ">
                    <input type="checkbox"
                           :ref="'check' + activite.id"
                           @click="enabled(activite.id)" />
                    <span class="act-libelle">&nbsp; &nbsp;{{ activite.libelle }}</span>
                  </div>
                  <div class="col-md-4 tb_row">
                    <textarea :ref="'obs' + activite.id"
                              class="form-control"
                              disabled
                              v-model="activites[activite.counter].observation"></textarea>
                  </div>
                  <div class="col-md-2 tb_row">
                    <input
                      @change="checked(activite.id)"
                      @input="addSpaceActivity(activite.counter)"
                      type="text"
                      :ref="'mont' + activite.id"
                      class="form-control"
                      disabled
                      v-model="activites[activite.counter].montant"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button_form programBtn">
        <button type="button"
                class="btn btn-f-blue"
                @click="submit"><i class="flaticon flaticon-floppy-disk"></i> Enregistrer</button>
        <button type="reset"
                class="btn btn-f-blue"
                data-toggle="modal"
                data-target="#exampleModal"><i class="flaticon flaticon-cancel"></i> &nbsp;&nbsp; Annuler</button>
      </div>
      <!-- modal box -->
      <div class="modal fade"
           id="exampleModal"
           tabindex="-1"
           role="dialog"
           aria-labelledby="exampleModalLabel"
           aria-hidden="true">
        <div class="modal-dialog"
             role="document">
          <div class="modal-content">
            <div class="modal-header ">
              <h5 class="modal-title text-uppercase"
                  id="exampleModalLabel">Annulation</h5>
              <button type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Cette action est irréversible, êtes-vous sure de vouloir annuler ?</p>
            </div>
            <div class="modal-footer">
              <button type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal">Non</button>
              <button type="button"
                      @click="goBack()"
                      data-dismiss="modal"
                      class="btn btn-primary">Oui</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import "./programme.css";
#notification {
  position: center;
}
form div.col-md-3 select.form-control{
  font-size: 0.6em;
}
form div.col-md-3 select.form-control option{
  font-size: 0.9em;
}
form div.col-md-3 select.form-control,
form div.col-md-3 input[type="text"],
form div.col-md-3 input[type="date"],
form div.col-md-3 input[type="number"],
form textarea
{
  height: fit-content;
}
.tableview .col-md-6 > span.act-libelle{
  text-decoration: capitalize;
}
</style>
<script>
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"
import { mapActions, mapMutations, mapGetters } from "vuex"
import utils from "@/assets/js/utils"
export default {
  name: "CreateProgramme",
  components: {
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    montant_initial_programme:"",
    montant_revise:"",
    montant_definitif:"",
    activites: [],
    form: form({
      nom_programme: "",
      montant_initial_programme: "",
      montant_revise: "",
      montant_definitif: "",
      date_debut: "",
      date_fin: "",
      description: "",
      date_validation_programme: "",
      date_lancement_programme: "",
      date_transmission_rapport_cloture_programme: "",
      date_cloture_programme: "",
      annee_exercice:"",
      categorie_programme: "programme_entretien_routier",
      activites: []
    }).rules({
      nom_programme: "required",
      montant_initial_programme: "required",
      date_debut: "required",
      date_fin: "required",
    }),
    formData: {
      nom_programme: "",
      montant_initial_programme: "",
      montant_revise: "",
      montant_definitif: "",
      date_debut: "",
      date_fin: "",
      description: "",
      annee_exercice:"",
      date_validation_programme: "",
      date_lancement_programme: "",
      date_transmission_rapport_cloture_programme: "",
      date_cloture_programme: "",
      categorie_programme: "",
      activites: []
    },
    countval: 0,
    send: false,
    activitesList: [],

    affiche: {
      nom_programme: "nom_programme:true",
      montant_initial_programme: "montant_initial_programme:true",
      date_debut: "date_debut:true",
      date_fin: "date_fin:true",
      description: "description:true",
      date_validation_programme: "date_validation_programme:true",
      date_lancement_programme: "date_lancement_programme:true",
      date_transmission_rapport_cloture_programme: "date_transmission_rapport_cloture_programme:true",
      date_cloture_programme: "date_cloture_programme:true",
    },
    gestion_date_debut: "date_debut",
    gestion_date_fin: "date_fin"
  }),
  watch: {
    ["form.data"]: {
      deep: true,
      immediate: false,
      handler: "onFormChange"
    },
    progrubriques() {
      const rubriqueArr = this.progrubriques.donnees
      var counter = 0
      var listActivities = []
      var listAct = []
      rubriqueArr.filter(function(actArr, rubindex) {
        actArr.activites.filter(function(activity, actindex) {
          const insertActivity = { id: "", observation: "", montant: "" }
          insertActivity.id = activity.id
          insertActivity.observation = activity.observation
          insertActivity.montant = activity.montant_budgete
          listActivities.push(insertActivity)
          listAct.push(insertActivity)
          rubriqueArr[rubindex].activites[actindex]["counter"] = counter
          counter += 1
        })
      })
      this.progrubriques.donnees = rubriqueArr
      this.activites = listAct
      this.form.activites = listActivities
    },
    singleProgrammes() {
      if (this.singleProgrammes) {
        // console.log(this.singleProgrammes)
        this.notif.message = this.singleProgrammes
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.singleProgrames("")
            this.$router.push({ name: "Programme" })
            // location.reload()
          }.bind(this),
          3000
        )
      }
    },
    msgFailProgram(){
      if (this.msgFailProgram) {
        this.notif.message = this.msgFailProgram
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgFailProgram("")
          }.bind(this),
          3000
        )
      }
    }
  },
  computed: {
    ...mapGetters(["progerrors", "singleProgrammes", "progrubriques","msgFailProgram"])
  },
  created() {
    this.getRubriques()
  },
  methods: {
    ...mapActions(["saveProgrammes", "getRubriques"]),
    ...mapMutations(["singleProgrames", "setErors","setMsgFailProgram"]),

    onFormChange() {
      //this.form.validate()
    },

    checked(id) {
      if (this.$refs["mont" + id][0].value == "0") {
        this.enabled(id)
        this.$refs["check" + id][0].checked = false
      }
      if (this.$refs["mont" + id][0].value.length > 15) {
        this.notif.message = "Le montant ne doit pas être supérieur à 999.999.999.999"
        this.notif.type = "toast-warning"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }
    },
    enabled(id) {
      if (this.$refs["obs" + id][0].disabled) {
        this.$refs["obs" + id][0].disabled = false
        this.$refs["mont" + id][0].disabled = false
      } else {
        this.$refs["obs" + id][0].value = ""
        this.$refs["obs" + id][0].disabled = true
        this.$refs["mont" + id][0].value = 0
        this.$refs["mont" + id][0].disabled = true
        this.activites.forEach(activite => {
          if (activite.id == id) {
            activite.montant = 0
            activite.observation = ""
          }
        })
      }
    },
    submit() {
      this.formData.nom_programme = this.form.data.nom_programme
      this.formData.montant_initial_programme = this.form.data.montant_initial_programme
      this.formData.montant_revise = this.form.data.montant_revise
      this.formData.montant_definitif = this.form.data.montant_definitif
      this.formData.date_debut = this.form.data.date_debut
      this.formData.date_fin = this.form.data.date_fin
      this.formData.description = this.form.data.description
      this.formData.date_validation_programme = this.form.data.date_validation_programme
      this.formData.date_lancement_programme = this.form.data.date_lancement_programme
      this.formData.date_transmission_rapport_cloture_programme = this.form.data.date_transmission_rapport_cloture_programme
      this.formData.date_cloture_programme = this.form.data.date_cloture_programme
      this.formData.annee_exercice = this.form.data.annee_exercice
      this.formData.categorie_programme = this.form.data.categorie_programme
      this.activitesList = []
      this.form.validate()
      // Verifier si le montant_total_activite <= montant_initial_programme
      var montant_total_activite=0
      this.activites.forEach(activite => {
        
        if (activite.montant.length > 3) {
          if (activite.montant.includes(" ")) {
            var montant= activite.montant.split(" ")
            var result=""
            for (let index = 0; index < montant.length; index++) {
              result+=montant[index]
            }
            montant_total_activite+=Number(result)
          }
        }else{
          montant_total_activite+=Number(activite.montant)
        }
        
      })
      if (montant_total_activite <= this.formData.montant_initial_programme) {
        if (
          !this.form
            .validate()
            .errors()
            .any()
        ) {
          this.activites.forEach(activite => {
            if (activite.observation != "" && activite.montant != 0) {
              if (activite.montant.includes(" ")) {
                var montant= activite.montant.split(" ")
                var res=""
                for (let index = 0; index < montant.length; index++) {
                  res+=montant[index]
                }
                activite.montant=Number(res)
              }
              this.activitesList.push(activite)
            }
          })
          this.send = true
          this.form.activites.forEach(activite => {
            if (activite.observation == "" && activite.montant != 0) {
              this.send = false
              var message = "Veuillez remplir tous les champs observation"
              this.notif.message = message
              this.notif.type = "toast-warning"
              this.notif.activated = "activated"
              setTimeout(
                function() {
                  this.notif.activated = ""
                }.bind(this),
                3000
              )
            }
          })
          if (this.send) {
            this.formData.activites = this.activitesList
            if (this.formData.activites.length != 0) {
              this.saveProgrammes(this.formData)
            } else {
              var message = "Veuillez ajouter au moins une activité"
              this.notif.message = message
              this.notif.type = "toast-warning"
              this.notif.activated = "activated"
              setTimeout(
                function() {
                  this.notif.activated = ""
                }.bind(this),
                3000
              )
            }
          }
        } else {
          for (let afficheKey in this.affiche) {
            const tabVale = this.affiche[afficheKey].split(":")
            if (tabVale.length === 1) {
              this.affiche[afficheKey] = tabVale[0] + ":true"
            }
          }
        }  
      }else{
        message = "Le montant total des activités doit être inférieur au montant initial du programme"
        this.notif.message = message
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
      
    },
    goBack() {
      this.$router.push({ name: "Programme" })
    },
    removeSPan(element) {
      for (let afficheKey in this.affiche) {
        const valeur = this.affiche[afficheKey].split(":")[0]
        if (valeur === element) {
          this.affiche[afficheKey] = valeur
        }
      }
    },
    checkbudget(budget) {
      if (budget > 999999999999) {
        var message = "Veuillez renseigner un montant inférieur à 999.999.999.999"
        this.notif.message = message
        this.notif.type = "toast-warning"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    //pour les montants activités
    addSpaceActivity(key) {
      var chaine = ""
      var splinter = ""
      splinter = this.activites[key].montant.split("")
      // var res =""
      if (/[0-9]/.test(this.activites[key].montant) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this.activites[key].montant)) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index]=""
          }
          chaine += splinter[index]

        }
        if (splinter.length >= 4) {
          // check if there's a space
          if (splinter.includes(" ")) {
            console.log("in")
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            trimer = chaine
            chaine = ""
            chaine = trimer.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      
      this.activites[key].montant = chaine
    },
    /**
     * voir dans src/assets/js/utils
     */
    getDate() {
      utils.getDate(this, this.gestion_date_debut, this.gestion_date_fin, "programme")
    }
  }
}
</script>
